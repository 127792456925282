<template>
    <div class="container swiper-sponsors pt-4 px-0 mt-4 mb-5">
        <h1 class="home-title d-flex justify-content-center text-uppercase mb-4">Some of our clients</h1>
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(client, index) in clients" :key="`client_${index}`">
                <Slider :values="client.values"/>
                <div class="separator"></div>
            </swiper-slide>
        </swiper>
        <div class="controls-container">
            <button class="button-prev" type="button" data-bs-slide="prev">
                <img src="/public-assets/slider-left" class="mx-2" alt="arrow">
            </button>
            <button class="button-next" type="button" data-bs-slide="next">
                <img src="/public-assets/slider-right" class="mx-2" alt="arrow">
            </button>
        </div>
    </div>
</template>

<script>
import Slider from './helpers/Slider.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    props: {
        clients: {type: Array, default: () => ([])}
    },
    components: {Swiper, SwiperSlide, Slider},
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                slidesPerColumn: 1.4,
                freeMode: true,
                mousewheel: true,
                touchReleaseOnEdges: true,
                navigation: {
                    nextEl: '.button-next',
                    prevEl: '.button-prev'
                }
            }
        }
    }
}
</script>

<style>
/*Swiper*/
.swiper-sponsors .swiper .swiper-slide {
    max-width: 200px !important;
}

.controls-container {
    height: 50px;
    display: flex;
    justify-content: end;
    gap: 10px;

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 51px;
    }
}

.swiper-slide {
    position: relative;
    margin: 0 !important;
    width: 200px !important;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .separator {
        position: absolute;
        height: 100px;
        width: 1px;
        background-color: #e5e5e5;
        right: 0;
        margin: 0;
    }
}

.swiper-sponsors .swiper {
    height: 350px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-sponsors .swiper .swiper-slide {
    height: 150px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1199px) {
    .swiper-sponsors .swiper .swiper-slide {
        height: 100px;
        margin-left: 30px;
        min-width: 2.18%;
        max-width: 2.18%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .swiper-sponsors .swiper .swiper-slide {
        height: 100px;
        margin-left: 30px;
        min-width: 3.15%;
        max-width: 3.15%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .swiper-sponsors .swiper .swiper-slide {
        height: 100px;
        margin-left: 30px;
        min-width: 3.87%;
        max-width: 3.87%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 575px) {
    .swiper-sponsors .swiper .swiper-slide {
        height: 100px;
        margin-left: 30px;
        min-width: 4.59%;
        max-width: 4.59%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.sponsor-slide-img {
    width: 100%;
}
</style>