<template>
    <div class="sponsor-slide-img">
        <img v-if="values.Image && values.Image.value"
             class="img-fluid"
             :class="{'image-btn': values.CTA && values.CTA.value}"
             :src="values.Image.value.public_path"
             :alt="values.Image.value.alt || values.Image.value.title"
             loading="lazy"
             width="100px"
             @click.prevent="redirect(values.CTA)"/>
    </div>
</template>

<script>
export default {
    props: {
        values: {
            type: Object,
            required: true
        }
    },
    methods: {
        redirect(cta) {
            if (cta?.value) {
                window.location.href = cta.value
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.image-btn {
    cursor: pointer;
}
</style>