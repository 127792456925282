
<div class="container swiper-sponsors pt-4 px-0 mt-4 mb-5">
    <h1 class="home-title d-flex justify-content-center text-uppercase mb-4">Some of our clients</h1>
    <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(client, index) in clients" :key="`client_${index}`">
            <Slider :values="client.values"/>
            <div class="separator"></div>
        </swiper-slide>
    </swiper>
    <div class="controls-container">
        <button class="button-prev" type="button" data-bs-slide="prev">
            <img src="/public-assets/slider-left" class="mx-2" alt="arrow">
        </button>
        <button class="button-next" type="button" data-bs-slide="next">
            <img src="/public-assets/slider-right" class="mx-2" alt="arrow">
        </button>
    </div>
</div>
